(function () {
  if (typeof s_omni !== 'object' || window.store.toLowerCase() !== 'walmart') return;

  $('#menu, #sidr').on('click', 'li a[href]', function (e) {
    const $a = $(this);
    let href = $a.attr('href');
    const text = pipeJoinedLinkLabels($a);

    if (!text
      || href.indexOf('upload=true') > -1
      || href.indexOf('www.walmart.com') > -1) {
      return;
    }

    if (['pathname', 'search'].every((key) => this[key] === location[key])) {
      // link is to the current page
      omniLinkClick('o', text);
      return;
    }

    if (href.indexOf('#') > -1) {
      href += `&mmomni=${encodeURIComponent(text)}`;
    } else {
      href += `#mmomni=${encodeURIComponent(text)}`;
    }

    $a.attr('href', href);
  });

  const hash = window.location.hash.split('#')[1] || '';
  const omniFreeHash = [];

  hash.split('&').forEach((part) => {
    const [key, value] = part.split('=');

    if (key === 'mmomni' && value) {
      omniLinkClick('o', decodeURIComponent(value));
    } else {
      omniFreeHash.push(part);
    }
  });

  // omniFreeHash contains all the hash params except mmomni
  const newHash = `#${omniFreeHash.filter(Boolean).join('&')}`;

  if (newHash != window.location.hash) window.location.hash = newHash;

  function pipeJoinedLinkLabels(element) {
    const isNavLink = element.is('.nav-link');
    const isSectionTitle = element.parent().is('.section-title');
    const isImage = element.children('img').length > 0;
    const topLinkText = element.closest('.nav-item').find('a:first').text();
    const subTitleText = isNavLink ? null : element.closest('.flyout-section').find('p').text();
    const linkText = isNavLink ? null : isSectionTitle ? null : isImage ? $(element.children('img')[0]).attr('alt') : element.text();

    return [topLinkText, subTitleText, linkText].filter(Boolean).join(' | ');
  }

  function omniLinkClick(linkType, linkName) {
    s_omni.linkTrackVars = 'prop54,events';
    s_omni.trackExternalLinks = false;
    const overrides = new Object();
    overrides.pageURL = [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
    const oldProp54 = s_omni.prop54;
    s_omni.prop54 = `mega-menu-nav | ${linkName}`;
    s_omni.tl(true, linkType, 'Link click', overrides);
    s_omni.prop54 = oldProp54;
  }
}());
